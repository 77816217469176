<template>

    <Header settings>Guides</Header>

    <nav class="container mb-6">
        <div class="overflow-x-auto grid">
            <div class="badges">
                <button class="badge badge-neutral whitespace-nowrap"
                        v-for="category in categories"
                        :key="category"
                        @click="setActiveTab(category)"
                        :class="{ active: category === activeTab }"
                >
                    <span class="me-2">{{ categoryEmojis[category] }}</span> {{ category }}
                </button>
            </div>
            <!--                <input type="text" v-model="search" placeholder="Search for a guide">-->
        </div>
    </nav>

    <section class="container overflow-x-auto grid gap-4">
        <div class="grid grid-cols-2 gap-2">
            <div class="card card-compact bg-black text-white overflow-hidden aspect-square" v-for="guide in filteredGuides" :key="guide.id">
                <div class="card-body flex flex-col justify-end z-10">
                    <div class="text-xs font-bold">{{ guide.title }}</div>
                </div>
                <div class="absolute top-0 left-0 z-0">
                    <img :src="require(`@/assets/guide/cover-${guide.id}.jpg`)" :alt="guide.id">
                    <div class="absolute bottom-0 bg-gradient-to-b from-transparent to-black w-full h-2/3"></div>
                </div>
            </div>
        </div>
    </section>
                    <!--                <img src="@/assets/logo.png" :alt="guide.id">-->
                    <!--                <p>{{ guide.categories }}</p>-->
<!--                    <p>{{ guide.text }}</p>-->

</template>


<script setup>
import { ref, computed } from 'vue';
import { getFirebaseGuides } from '@/api/firebase.js';

import Header from "@/components/Header.vue";

const guides = getFirebaseGuides();
const categories = [...new Set(guides.map(guide => guide.categories))];
const activeTab = ref(categories[0]); // Set the initial active tab to the first category
const filteredGuides = computed(() => {
    return guides.filter(guide => guide.categories === activeTab.value);
});

function setActiveTab(category) {
    activeTab.value = category;
}

const categoryEmojis = {
    "Basic": "🔮",
    "Types": "👑",
    "Planets": "🪐",
    "Profiles": "📚️",
    "Authorities": "👑"
    // Add more categories and corresponding emojis as needed
};
</script>

<!--
<style>
.guides {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.guides .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.guides .card .card-body {
    padding-top: 8rem;
    /*min-height: 16vh;*/
}
.guides .card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(&#45;&#45;tg-theme-text-color), transparent);
    z-index: 1;
}
.guides .card small {
    display: block;
    /*padding-top: 5rem;*/
    position: relative;
    z-index: 2;
    color: var(&#45;&#45;tg-theme-button-text-color);
    font-weight: 500;
}
.guides .card img {
    width: 180%;
    top: -20%;
    left: -40%;
}
</style>-->
