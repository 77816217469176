// import { ref, computed } from 'vue';


/* BODYGRAPH */
import bodyminiData from '../data/astro/bodymini.json';

export function getUserBodymini() {
    return bodyminiData;
}

export function getUserBodyminiDesign() {
    return bodyminiData.D.data;
}
export function getUserBodyminiPersonality() {
    return bodyminiData.P.data;
}

export function getUserBodyminiProfile() {
    return bodyminiData.profile;
}
export function getUserBodyminiType() {
    return bodyminiData.type;
}

export function getUserBodyminiCross() {
    return getUserBodymini().cross;
}

export function getUserBodyminiVariables() {
    return getUserBodymini().variables;
}





/* Active gates */
/*export function getAstroActiveGates() {
    const gates = bodyminiData.canal;
    const gatesList = Object.values(gates);
    return gatesList;
}*/







/* TRANSIT */
import transitsData from '../data/astro/tranzit.json';
export function getUserTransits() {
    return transitsData;
}
// Transit active gates
/*const planets = ref(transitsData);
const getTransitActiveGates = computed(() => {
    return Object.keys(planets.value).map(key => ({
        planet: key,
        gate: planets.value[key].gate.gate,
    }));
});*/

/* Get transit gates */
export function getTransitGates() {
    const stars = Object.keys(transitsData);
    const numGzForStars = {};

    // Все
    // stars.forEach(star => {
    //     numGzForStars[star] = transitsData[star].gate.numgz;
    // });

    // Без TRUE_NODE, SOUTH_NODE, CHIRON
    stars.forEach(star => {
        if (star !== 'TRUE_NODE' && star !== 'SOUTH_NODE' && star !== 'CHIRON') {
            numGzForStars[star] = transitsData[star].gate.numgz;
        }
    });

    return numGzForStars;
}

// get (calc) lucky number
export function getLuckyNumber() {
    const transitGates = getTransitGates();
    const gateValues = Object.values(transitGates);

    const sum = gateValues.reduce((a, b) => a + b, 0);
    const luckyNumber = sum / gateValues.length;

    return Math.floor(luckyNumber / Math.PI);
}





/* Axios */
/*import axios from 'axios';
const API_URL = 'https://api.astroapi.ru';
export async function getUserTransits(userData) {
    const { firstName, lastName, gender, birthCity, birthDate, birthTime } = userData;

    const response = await axios.get(`${API_URL}/transit`, {
        headers: {
            'X-API-KEY': process.env.VUE_APP_ASTRO_API_KEY
        },
        params: {
            firstName,
            lastName,
            gender,
            birthCity,
            birthDate,
            birthTime
        }
    });

    return response.data;
}*/



