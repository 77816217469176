<template>

    <Header settings :description="storageProfileType + storageProfileCombination + storageProfileRoleModelTitle">
<!--        storageProfileType + storageProfileCombination + storageProfileRoleModelTitle-->
<!--        {{userData.name}}-->
        Bodygraph
    </Header>

    <div class="container">
        <h2 class="title">You're Manifest Generator</h2>
    </div>

    <div class="container flex gap">

        <!-- Design (red) -->
        <div class="design-gates text-xs">
            <div class="text-error mb-1"><b>Design</b></div>
            <template v-for="(element, planet) in bodyminiDesign" :key="planet">
                <div class="flex gap-1">
                    <span class="text-error"><PlanetSign :planet="planet.toLowerCase()" /></span>
                    <span>{{ element.gate.gate }}.{{ element.gate.line }}</span>
                </div>
            </template>
        </div>

        <!-- Bodygraph -->
        <div class="overflow-x-auto grow">
            <Bodygraph class="mb-4" />
        </div>

        <!-- Personality (blue) -->
        <div class="personality-gates text-xs">
            <div class="text-primary mb-1"><b>Personality</b></div>
            <div class="grid justify-end">
                <template v-for="(element, planet) in bodyminiPersonality" :key="planet">
                    <div class="flex gap-1">
                        <span>{{ element.gate.gate }}.{{ element.gate.line }}</span>
                        <span class="text-primary"><PlanetSign :planet="planet.toLowerCase()" /></span>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <div class="container grid gap mt-4">
        <p class="text-muted text-center text-xxs">
            This is your BodyGraph — a graphic illustration of the energetic flow within your system, a blueprint for how you operate and interact with the world.
        </p>
        <p>
            Your BodyGraph shows your Definition (anything colored in) and your openness. Definition reveals who you are, and remains consistent throughout your life. Your openness is where you are susceptible to conditioning, and can result in what Human Design calls the Not-Self.
        </p>
        <div class="card">
            <div class="card-body">
                <h3 class="title">My BodyGraph analysis</h3>
                <p>Whether you're single or co-mingled, it can be helpful to know how you operate in relationships and how your partner - or potential partner - does too, and whether this is an ideal love connection.</p>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref } from 'vue';

// import { userData } from "@/data/user";

import {
    getUserBodyminiDesign,
    getUserBodyminiPersonality,
    // getUserBodyminiProfile,
    // getUserBodyminiProfileName,
    // getUserBodyminiType
} from '@/api/astro.js';

const bodyminiDesign = ref(getUserBodyminiDesign());
const bodyminiPersonality = ref(getUserBodyminiPersonality());
// const bodyminiProfile = ref(getUserBodyminiProfile());
// const bodyminiType = ref(getUserBodyminiType());

// NEW
import {
    storageProfileType,
    storageProfileCombination,
    storageProfileRoleModelTitle,
} from '@/data/storage.js';

// Components
import Header from "@/components/Header.vue";
import PlanetSign from "@/components/PlanetSymbol.vue";
import Bodygraph from "@/components/Bodygraph.vue";


// import { getFirebaseStorageRoleModelTitleById } from '@/services/firebase.js';


// import {getFirebaseStorageRoleModel} from '@/api/firebase.js';
// const firebaseRoleModel = ref(getFirebaseStorageRoleModel);
//
// watch(bodyminiProfile, (newProfile) => {
//     firebaseRoleModel.value = getFirebaseStorageRoleModel(newProfile);
// }, { immediate: true });

// import {getFirebaseStorageProfileData} from '@/services/firebase.js';
// const firebaseStorageProfile = ref(getFirebaseStorageProfileData());

// import { getFirebaseStorageProfileData } from '@/api/firebase.js';
// const firebaseStorageProfile = ref(getFirebaseStorageProfileData());
</script>