<template>
    <div class="navigation">
        <router-link
            v-for="route in allRoutes"
            :key="route.path"
            :to="route.path"
            @click="scrollToTop"
            active-class="active"
            class="navigation-item"
            :aria-label="`Navigate to ${route.name}`"
        >
            <Svg class="navigation-icon" :src="icons[route.name]" :alt="route.name" />
            <div class="navigation-text">{{ route.name }}</div>
        </router-link>

<!--        <router-link
            v-for="route in routes"
            :key="route.name"
            :to="route.path"
            @click="scrollToTop"
            active-class="active"
            class="navigation-item"
            :aria-label="`Navigate to ${route.name}`"
        >
            <Svg class="navigation-icon" :src="route.icon" :alt="route.name" />
            <div class="navigation-text">{{ route.name }}</div>
        </router-link>-->
    </div>
</template>

<script setup>
import { reactive, computed/*, onMounted, onUnmounted*/ } from 'vue';

// Component
import Svg from '@/components/Svg.vue';


// Router
import { useRouter } from 'vue-router'
const router = useRouter();
const allRoutes = computed(() => router.options.routes.filter(route => route.navigation));

const icons = {
    Personality: require('@/assets/navigation/personality.svg'),
    Bodygraph: require('@/assets/navigation/bodygraph.svg'),
    Transits: require('@/assets/navigation/transits.svg'),
    Guides: require('@/assets/navigation/guide.svg'),
};

// rout
/*const routes = reactive([
    { name: 'Personality', path: '/personality', icon: icons.personalityIcon },
    { name: 'Bodygraph', path: '/bodygraph', icon: icons.bodygraphIcon },
    { name: 'Daily Transits', path: '/transits', icon: icons.transitsIcon },
    { name: 'Guides', path: '/guides', icon: icons.guideIcon },
]);*/

// back to top button
function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}



/*function handleScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const bottomOffset = 1; // расстояние до конца страницы, при котором прокрутка вниз будет запрещена

    if (scrollTop + windowHeight >= documentHeight - bottomOffset) {
        window.scrollTo({
            /!*top: scrollTop - 1,*!/
            /!*behavior: 'smooth'*!/
        });
    }
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});*/
</script>

<style lang="scss">
.navigation {
    @apply w-full;
    @apply z-20 sticky bottom-0 left-0;
    @apply flex place-content-around;

    height: 4.5rem; // еще задается в style.scss
    max-height: 4.5rem;

    border-width: 0.5px;
    @apply border-t-muted border-solid ;
    @apply bg-base-100 backdrop-blur-sm ;

    @apply border-opacity-50 bg-opacity-90;
}
.navigation .navigation-icon {
    @apply block mx-auto w-6 h-6 mb-0.5;
}
.navigation .navigation-text {
    @apply text-xxs;
}
.navigation .navigation-item {
    text-decoration: none;
    //@apply pt-2 pb-8;
    @apply p-2;
    flex: 1;
    text-align: center;
    /*color: var(--tg-theme-text-color);*/
}
.navigation .navigation-item:not(.active) {
    /*opacity: 0.6;*/
    @apply text-muted;
}

.navigation .navigation-item.active {
    /*background: rgba(var(--tg-theme-secondary-bg-color), 0.5);*/
    &, .icon {
        @apply text-primary;
    }
}

</style>
