<template>
    <Header description="Update your account's profile information and email address">
        Profile
    </Header>

    <div class="container grid gap">

        <!-- Name -->
        <form class="card card-compact">
            <div class="card-body grid gap">
                <div>
                    <h3 class="title mb-1">Your name</h3>
                </div>
                <input type="text" placeholder="Name" class="input input-bordered" />
            </div>
        </form>

        <!-- Gender -->
        <form class="card card-compact">
            <div class="card-body grid gap">
                <h3 class="title">Gender</h3>
                <div>
                    <div class="form-control">
                        <label class="label cursor-pointer flex gap-2">
                            <input type="radio" name="radio-10" class="radio" checked="checked" />
                            <span class="label-text grow">Male</span>
                        </label>
                    </div>

                    <div class="form-control">
                        <label class="label cursor-pointer flex gap-2">
                            <input type="radio" name="radio-10" class="radio"/>
                            <span class="label-text grow">Female</span>
                        </label>
                    </div>

                    <div class="form-control">
                        <label class="label cursor-pointer flex gap-2">
                            <input type="radio" name="radio-10" class="radio"/>
                            <span class="label-text grow">Other</span>
                        </label>
                    </div>
                </div>
            </div>
        </form>

        <!-- Date of birth -->
        <form class="card card-compact">
            <div class="card-body grid gap">

                <div>
                    <h3 class="title mb-1">Date of birth</h3>
                    <p>Update your account's profile information and email address</p>
                </div>

<!--                <p>
                    <input type="date" v-model="selectedDate">
                </p>-->

<!--                <div>
                    <select v-model="selectedDay">
                        <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                    </select>

                    <select v-model="selectedMonth">
                        <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
                    </select>

                    <select v-model="selectedYear">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>-->

                <div class="grid gap-2">

                    <label class="form-control grow">
                        <select class="select select-bordered">
                            <option disabled>Day</option>
                            <option selected>12</option>
                        </select>
                    </label>

                    <label class="form-control grow">
                        <select class="select select-bordered">
                            <option disabled>Month</option>
                            <option selected>December</option>
                        </select>
                    </label>

                    <label class="form-control grow">
                        <select class="select select-bordered">
                            <option disabled>Year</option>
                            <option selected>2000</option>
                        </select>
                    </label>

                </div>
            </div>
        </form>

        <!-- Date of birth -->
        <form class="card card-compact">
            <div class="card-body grid gap">

                <div>
                    <h3 class="title mb-1">Time of birth</h3>
                    <p>Update your account's profile information and email address</p>
                </div>

                <div class="grid gap-2">

                    <label class="form-control grow">
                        <select class="select select-bordered">
                            <option disabled>Hours</option>
                            <option selected>12</option>
                        </select>
                    </label>

                    <label class="form-control grow">
                        <select class="select select-bordered">
                            <option disabled>Minutes</option>
                            <option selected>10</option>
                        </select>
                    </label>

                    <label class="form-control grow">
                        <select class="select select-bordered">
                            <option disabled>Period</option>
                            <option selected>PM</option>
                        </select>
                    </label>

                </div>
            </div>
        </form>

        <!-- Place of birth -->
        <form class="card card-compact">
            <div class="card-body grid gap">
                <div>
                    <h3 class="title mb-1">Place of birth</h3>
                </div>
                <input type="text" placeholder="Type a city..." class="input input-bordered" />
            </div>
        </form>

    </div>
</template>

<script setup>
// Components
import Header from "@/components/Header.vue";

//
import { ref } from 'vue';
const selectedDate = ref('2000-04-01');

//
const selectedDay = ref(1);
const selectedMonth = ref(4);
const selectedYear = ref(2000);

const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];
const years = Array.from({ length: 50 }, (_, i) => 1970 + i);
</script>