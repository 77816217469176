<script setup>
import { ref, computed, onMounted } from 'vue';

import {
    getUserTransits,
    getUserBodyminiDesign,
    getUserBodyminiPersonality
} from '@/api/astro.js'


const bodyminiDesign = ref(getUserBodyminiDesign());
const bodyminiPersonality = ref(getUserBodyminiPersonality());
const userTransits = computed(() => getUserTransits());

// Components
import PlanetSign from "@/components/PlanetSymbol.vue";


const svgSize = 300; // размер SVG контейнера
const circleRadius = 10; // радиус кругов
const planets = ref([]);

const getCirclePosition = (grad) => {
    const angle = (grad / 180) * Math.PI;
    const radius = svgSize / 2 - circleRadius; // радиус круга
    const x = svgSize / 2 + radius * Math.cos(angle);
    const y = svgSize / 2 - radius * Math.sin(angle);
    return { x, y };
};

/*const loadTransits = async () => {
    const data = await getUserTransits();
    planets.value = Object.entries(data).map(([name, details]) => ({
        name,
        grad: details.grad,
        color: 'blue',
    }));
};*/

const excludedPlanets = ['TRUE_NODE', 'SOUTH_NODE', 'CHIRON'];

const loadTransits = async () => {
    const data = await getUserTransits();
    planets.value = Object.entries(data)
        .filter(([name]) => !excludedPlanets.includes(name))
        .map(([name, details]) => ({
            name: name.toLowerCase(),
            grad: Math.floor(details.grad),
            color: 'blue',
        }));
};

onMounted(loadTransits);
</script>

<template>
<div class="container flex gap-2 text-xs">

    <!-- Design (red) -->
    <div class="design-gates">
        <div class="text-error mb-1"><b>Design</b></div>
        <div class="flex gap-1 cursor-default">
            <div>
                <span class="flex gap-1 tooltip tooltip-top tooltip-error" v-for="(element, planet) in bodyminiDesign" :key="planet.toLowerCase()" :data-tip="planet.toLowerCase()"> <!--TODO: выводить из Storage-->
                    <span>{{ element.gate.gate }}.{{ element.gate.line }}</span>
                    <span class="text-error" :data-tip="planet">
                        <PlanetSign :planet="planet.toLowerCase()" />
                    </span>
                </span>
            </div>
            <div>
                <span class="flex gap-1" v-for="(element, planet) in userTransits" :key="planet"> <!--TODO: выводить из Storage-->
                    {{ element.gate.gate }}.{{ element.gate.line }}
                </span>
            </div>
        </div>
    </div>

    <!-- Chart -->
    <div class="my-4 flex flex-1 justify-center items-center">
        <div class="transits">
            <template v-for="planet in planets" :key="planet.name">
                <div class="transit-orbit cursor-default"
                     :class="'transit-orbit-' + planet.name"
                     :data-deg="planet.grad"
                     :style="'transform: rotate(' + planet.grad + 'deg)'"
                >
                    <div class="star absolute mx-auto flex justify-center items-center" >
<!--                            <span class="opacity-20">{{planet.name}}</span>-->
                        <span class="text-white opacity-40 text-xxs">
                            <PlanetSign :planet="planet.name.toLowerCase()" />
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <!-- Personality (blue) -->
    <div class="personality-gates grid justify-end">
        <div class="text-primary mb-1"><b>Personality</b></div>
        <div class="flex justify-center items-center gap-2 tooltip tooltip-top tooltip-primary" v-for="(element, planet) in bodyminiPersonality" :key="planet" :data-tip="planet"> <!--TODO: выводить из Storage-->
            <span>{{ element.gate.gate }}.{{ element.gate.line }}</span>
            <span class="text-primary" >
                <PlanetSign :planet="planet.toLowerCase()" />
            </span>
        </div>
    </div>
</div>

<!--    <div class="transits my-4 hidden">
        <div class="transit-orbit transit-orbit-1">
        </div>
        <div class="transit-orbit transit-orbit-2"></div>
        <div class="transit-orbit transit-orbit-3"></div>
        <div class="transit-orbit transit-orbit-4"></div>
        <div class="transit-orbit transit-orbit-5"></div>
        <div class="transit-orbit transit-orbit-6"></div>
        <div class="transit-orbit transit-orbit-7"></div>
        <div class="transit-orbit transit-orbit-8"></div>
        <div class="transit-orbit transit-orbit-9">
            <div class="star absolute mx-auto bg-blue-500">9</div>
        </div>
        <div class="transit-orbit transit-orbit-10">
            <div class="star absolute mx-auto bg-red-500">10</div>
        </div>
    </div>-->

<!--    <code class="grid gap-2 text-xxs">
        <div v-for="planet in planets" :key="planet.name">
            <b class="capitalize">
                {{planet.name}}
            </b>
            <p>deg: {{planet.grad}}</p>
            <p>Position X: {{ Math.floor(getCirclePosition(planet.grad).x) }}</p>
            <p>Position Y: {{ Math.floor(getCirclePosition(planet.grad).y) }}</p>
        </div>
    </code>-->

<!--    <div class="transits-container">
        <svg :width="svgSize" :height="svgSize">
            <circle
                v-for="planet in planets"
                :key="planet.name"
                :class="planet.name"
                :cx="getCirclePosition(planet.grad).x"
                :cy="getCirclePosition(planet.grad).y"
                :r="circleRadius"
                :fill="planet.color"
            />
        </svg>
    </div>-->
</template>


<style lang="scss">
$transits-wrapper-size: 180px;
$transits-star-size: 16px;

.transits {
    position: relative;
    display: block;
    width: $transits-wrapper-size;
    height: $transits-wrapper-size;
}

.transit {
    &-orbit {
        position: absolute;
        border-radius: 1000px;
        border: 0.5px dashed;

        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                animation-delay: #{$i * 10ms};
            }
        }

        @keyframes slow-spin {
            from {
                //transform: rotate(0deg);
            }
            to {
                transform: rotate(-1360deg);
            }
        }

        .star {
            width: $transits-star-size;
            height: $transits-star-size;
            top: calc(#{$transits-star-size} / 2 * (-1));
            border-radius: 1000px;
            position: relative;
            @apply text-xs capitalize;
        }

        &-sun {
            top: 50%;
            left: 50%;
            width: calc(100% - 50% * 2);
            height: calc(100% - 50% * 2);
            animation: slow-spin 50s linear infinite;
            @apply border-gray-500;
            .star {
                @apply bg-gray-500;
            }
        }
        &-earth {
            top: 45%;
            left: 45%;
            width: calc(100% - 45% * 2);
            height: calc(100% - 45% * 2);
            animation: slow-spin 55s linear infinite;
            @apply border-red-500;
            .star {
                @apply bg-red-500;
            }
        }
        &-moon {
            top: 40%;
            left: 40%;
            width: calc(100% - 40% * 2);
            height: calc(100% - 40% * 2);
            animation: slow-spin 60s linear infinite;
            @apply border-gray-500;
            .star {
                @apply bg-gray-500;
            }
        }
        &-mercury {
            top: 35%;
            left: 35%;
            width: calc(100% - 35% * 2);
            height: calc(100% - 35% * 2);
            animation: slow-spin 65s linear infinite;
            @apply border-red-500;
            .star {
                @apply bg-red-500;
            }
        }
        &-venus {
            top: 30%;
            left: 30%;
            width: calc(100% - 30% * 2);
            height: calc(100% - 30% * 2);
            animation: slow-spin 70s linear infinite;
            @apply border-gray-500;
            .star {
                @apply bg-gray-500;
            }
        }
        &-mars {
            top: 25%;
            left: 25%;
            width: calc(100% - 25% * 2);
            height: calc(100% - 25% * 2);
            animation: slow-spin 75s linear infinite;
            @apply border-red-500;
            .star {
                @apply bg-red-500;
            }
        }
        &-jupiter {
            top: 20%;
            left: 20%;
            width: calc(100% - 20% * 2);
            height: calc(100% - 20% * 2);
            animation: slow-spin 80s linear infinite;
            @apply border-gray-500;
            .star {
                @apply bg-gray-500;
            }
        }
        &-saturn {
            top: 15%;
            left: 15%;
            width: calc(100% - 15% * 2);
            height: calc(100% - 15% * 2);
            animation: slow-spin 85s linear infinite;
            @apply border-red-500;
            .star {
                @apply bg-red-500;
            }
        }
        &-uranus {
            top: 10%;
            left: 10%;
            width: calc(100% - 10% * 2);
            height: calc(100% - 10% * 2);
            animation: slow-spin 90s linear infinite;
            @apply border-gray-500;
            .star {
                @apply bg-gray-500;
            }
        }
        &-neptune {
            top: 5%;
            left: 5%;
            width: calc(100% - 5% * 2);
            height: calc(100% - 5% * 2);
            animation: slow-spin 95s linear infinite;
            @apply border-red-500;
            .star {
                @apply bg-red-500;
            }
        }
        &-pluto {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: slow-spin 100s linear infinite;
            @apply border-gray-500;
            .star {
                @apply bg-gray-500;
            }
        }
    }
}
</style>