<template>
    <figure v-html="svgContent" :class="classes" />
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    classes: {
        type: String,
        default: ''
    },
});

const svgContent = ref('');

onMounted(() => {
    loadSvg();
});

function loadSvg() {
    const svgPath = props.src;
    fetch(svgPath)
        .then(response => response.text())
        .then(svgData => {
            let parser = new DOMParser();
            let svgDoc = parser.parseFromString(svgData, "image/svg+xml");
            if (props.classes) {
                svgDoc.querySelector('svg').classList.add(props.classes);
            }
            svgContent.value = new XMLSerializer().serializeToString(svgDoc);
        })
        .catch(error => {
            console.error('Error loading SVG:', error);
        });
}
</script>