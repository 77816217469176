<template>
    {{ planetSymbol }}
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';

const props = defineProps({
    planet: {
        type: String,
        required: true
    }
});

const planetSymbols = {
    sun:        '☉',
    earth:      '⊕',
    moon:       '☽',
    mercury:    '☿',
    venus:      '♀',
    mars:       '♂',
    jupiter:    '♃',
    saturn:     '♄',
    uranus:     '♅',
    neptune:    '♆',
    pluto:      '♇',
    chiron:     '⚷',
    true_node:  '☊',
    south_node: '☋'
};

const planetSymbol = computed(() => planetSymbols[props.planet] || '');
</script>

<!--
☉ - Солнце (Sun)
☽ - Луна (Moon)
☿ - Меркурий (Mercury)
♀ - Венера (Venus)
♂ - Марс (Mars)
♃ - Юпитер (Jupiter)
♄ - Сатурн (Saturn)
♅ - Уран (Uranus)
♆ - Нептун (Neptune)
♇ - Плутон (Pluto)-->
