<template>

    <Header settings>
        {{currentDate}}
    </Header>

    <div class="container grid gap">

        <!-- Tabs -->
        <nav>
            <div role="tablist" class="flex bg-neutral p-1 rounded">
                <button role="tab" :class="tabClass('Today')" @click="updateActiveTab('Today')">Today</button>
                <button role="tab" :class="tabClass('Gates')" @click="updateActiveTab('Gates')">Gates</button>
                <button role="tab" :class="tabClass('Channels')" @click="updateActiveTab('Channels')">Channels</button>
            </div>
        </nav>

        <!-- Title -->
        <template v-if="activeTab === 'Today'">
            <div>
                <h2 class="title">Roman, Your Day..</h2>
                <p>The details of your project may seem confusing today. If your day is filled with unusual ideas, follow them.</p>
            </div>
            <TransitsChart></TransitsChart>
        </template>

        <!-- Gates -->
        <template v-if="activeTab === 'Gates'">
            <div>
                <h2 class="title">Active Gates</h2>
                <p>Activated gates mark energy centres in the individual chart, highlighting potential strengths and weaknesses. Each gateway represents a unique aspect of personality and behaviour, offering insight into how these energies manifest in everyday life.</p>
            </div>
            <div class="grid gap">
                <div class="card" v-for="(value, key) in transitGates" :key="key">
                    <div class="card-body">
                        <h3 class="title flex">
                            <span class="grow">{{ key }}_{{ value }}_title</span>
                            <span class="badge">{{ value }}11</span>
                        </h3>
                        <p>{{ key }}_{{ value }}_description</p>
                    </div>
                </div>
            </div>
        </template>

        <!-- Channels -->
        <template v-if="activeTab === 'Channels'">
            <div>
                <h2 class="title">Active Channels</h2>
                <p>Activated channels mark the connection between two specific centres in the individual chart, revealing integrated themes and abilities. Each channel represents a unique combination of energies that influence personality traits, behavioural patterns and life experiences.</p>
            </div>
            <div class="grid gap">
                <div class="card" v-for="(value, key) in bodyminiData.canal" :key="key">
                    <div class="card-body">
                        <h3 class="title flex">
                            <span class="grow">{{ key }}_title</span>
                            <span class="badge">{{ value }}</span>
                        </h3>
                        <p>{{ key }}_description</p>
                    </div>
                </div>
            </div>
        </template>
    </div>

</template>

<script setup>
import { ref } from 'vue';
import { getUserBodymini, getTransitGates } from '@/api/astro.js';

import Header from "@/components/Header.vue";

// Components
import TransitsChart from "@/components/TransitsChart.vue";
// import HelloWorld from "@/components/HelloWorld.vue";

const bodyminiData = getUserBodymini();
const transitGates = getTransitGates();

const currentDate = ref(new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }));

const activeTab = ref('Today');
const updateActiveTab = (tabName) => {
    activeTab.value = tabName;
};
const tabClass = (tabName) => activeTab.value === tabName ? 'border-0 flex-1 btn btn-sm h-[40px] btn btn-primary rounded-sm' : 'border-0  flex-1 btn btn-sm h-[40px] btn-ghost hover:bg-transparent text-muted hover:text-primary rounded-sm';

</script>
