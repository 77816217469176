import firebaseGuidesData from '../data/firebase/guides/all_en.json';
export function getFirebaseGuides() {
    return firebaseGuidesData;
}

import firebaseStorageRoleModelData from '../data/firebase/content/storage/storage_en_profile.json';
// export function getFirebaseStorageRoleModel() {
//     return firebaseStorageRoleModelData;
// }

export function getFirebaseStorageRoleModelTitleById(id) {
    const roleModel = firebaseStorageRoleModelData.find(item => item.id === id);
    return roleModel ? roleModel.title : null;
}

export const roleModelTitle = getFirebaseStorageRoleModelTitleById("6/2");
// role model title
// export function getFirebaseStorageRoleModelTitle() {
//     return firebaseStorageRoleModelData.title;
// }

import firebaseKeyFeaturesData from '../data/firebase/content/key_features_en.json';
export function getFirebaseKeyFeatures() {
    return firebaseKeyFeaturesData;
}

import firebaseCrossData from '../data/firebase/content/cross_en.json';
export function getFirebaseCross() {
    return firebaseCrossData;
}

import firebaseLuckyGemData from '../data/firebase/content/gem_en.json';
export function getFirebaseLuckyGem() {
    return firebaseLuckyGemData;
}

import firebaseGeneticTrauma from '../data/firebase/content/trauma_en.json';
export function getFirebaseGeneticTrauma() {
    return firebaseGeneticTrauma;
}

import firebaseBusiness from '../data/firebase/content/business_en.json';
export function getFirebaseBusiness() {
    return firebaseBusiness;
}

import firebaseVariables from '../data/firebase/content/variables_en.json';
export function getFirebaseVariables() {
    return firebaseVariables;
}