import {ref, computed} from 'vue';

import {
    getUserBodyminiProfile,
    getUserBodyminiType,
    getLuckyNumber,

    getUserTransits,
    // getUserBodymini,

    getUserBodyminiCross,
    getUserBodyminiVariables,

    getUserBodymini
} from '@/api/astro.js';


import {
    getFirebaseKeyFeatures,
    getFirebaseCross,
    getFirebaseGeneticTrauma,
    getFirebaseLuckyGem,

    getFirebaseBusiness,
    getFirebaseVariables,
} from '@/api/firebase.js';


// Bodygraph
export const storageBodyminiBodygraph = ref(getUserBodymini());

/* PROFILE */

export const storageProfileCombination = ref(getUserBodyminiProfile()); // 6/2

export const storageProfileType = ref(getUserBodyminiType()); // Manifestor
export const storageProfileRoleModelTitle = ref('The opportunist-role model');
export const storageProfileRoleModelDescription = ref('The opportunist-role models make new acquaintances easily...');


/* TRANSITS */
export const storageTransitsData = ref(getUserTransits());


/*** PERSONALITY ***/

// Lucky number
export const storagePersonalityLuckyNumber = ref(getLuckyNumber());
export const storagePersonalityLuckyNumberDescription = ref('Your Radical number is 10. Its ruler is Mars. Mars is regarded as commander - in - chief of planet. Therefore you will also have a desire to become a General, chief, leader etc in social field. You would like to monopolize in your job or business. By virtue of your inherent courage, you will cross hurdles easily by showing daring and nerves. By nature, you will be quick and in hurry. You will strive to finish your work as early as possible. As you are a headstrong person, you should keep yourself away from reckless ventures. You are disciplined your self and appreciate discipline from others. You may be misled by flattery and sycophancy, which may harm you. Keep yourself away from sycophants. You may be a short-tempered person due to the influence of Mars. This will generate many opponents for you. Though, your enemies will be less, as you will ever have capacity to crush your enemies.You may suffer from diseases related to fire elements. Therefore, you are advised to remain calm and quite for good luck.');

// Key qualities
export const storagePersonalityKeyQualities = computed(() => {
    const allKeyFeatures = getFirebaseKeyFeatures();
    return allKeyFeatures.filter(feature =>
        feature.profile === storageProfileCombination.value && feature.typeOfPersonality === storageProfileType.value
    );
});

// Basic indicators
export const storagePersonalityBasicIndicators = computed(() => {
    const allKeyFeatures = getFirebaseKeyFeatures();
    return allKeyFeatures
        .filter(feature =>
            feature.profile === storageProfileCombination.value && feature.typeOfPersonality === storageProfileType.value
        )
        .map(({ metal, flower, color, dayOfWeek, planet }) => ({
            metal,
            flower,
            color,
            dayOfWeek,
            planet
        }));
});

// Lucky number
export const storagePersonalityL = computed(() => {
    const allKeyFeatures = getFirebaseKeyFeatures();
    return allKeyFeatures
        .filter(feature =>
            feature.profile === storageProfileCombination.value && feature.typeOfPersonality === storageProfileType.value
        )
        .map(({ metal, flower, color, dayOfWeek, planet }) => ({
            metal,
            flower,
            color,
            dayOfWeek,
            planet
        }));
});

// Cross
export const storagePersonalityCross = computed(() => {
    const allCrosses = getFirebaseCross();
    const userCrossNum = getUserBodyminiCross().crossnum;
    const matchingCross = allCrosses.find(cross => cross.id === userCrossNum);

    return matchingCross ? matchingCross : null; //TODO: показывать ошибку
});

// Lucky gem
export const storagePersonalityLuckyGem = computed(() => {
    const keyFeatures = getFirebaseKeyFeatures();
    const luckyGems = getFirebaseLuckyGem();
    const userProfile = keyFeatures.find(feature =>
        feature.typeOfPersonality === storageProfileType.value &&
        feature.profile === storageProfileCombination.value
    );
    if (!userProfile) return null;
    const userGem = luckyGems.find(gem => gem.id === userProfile.gem);
    return userGem ? userGem : null;
});
/*export const storagePersonalityLuckyGem = computed(() => {
    const keyFeatures = getFirebaseKeyFeatures();
    const luckyGems = getFirebaseLuckyGem();
    const userProfile = keyFeatures.find(feature =>
        feature.typeOfPersonality === storageProfileType.value &&
        feature.profile === storageProfileCombination.value
    );
    if (!userProfile) return null;
    const userGem = luckyGems.find(gem => gem.id === userProfile.gem);
    return userGem ? userGem : null;
});*/

/*
export const storagePersonalityLuckyGem = computed(() => {
    /!*const allLuckyGems = getFirebaseLuckyGem();
    const matchingGem = allLuckyGems.find(gem => gem.id === storagePersonalityLuckyNumber.value);

    return matchingGem ? matchingGem : null; //TODO: показывать ошибку*!/

    return 'Ruby';
});
*/


// Genetic Trauma
export const storageGeneticTrauma = computed(() => {
    const luckyNumberHalf = storagePersonalityLuckyNumber.value / 2; //todo: как-то не очень
    const traumas = getFirebaseGeneticTrauma();
    const matchingTrauma = traumas.find(trauma => trauma.id === luckyNumberHalf);
    return matchingTrauma ? matchingTrauma : null;
});

// Business
export const storageBusiness = computed(() => {
    const businessData = getFirebaseBusiness();
    const matchingEntry = businessData.find(entry =>
        entry.typeOfPersonality === storageProfileType.value &&
        entry.profile === storageProfileCombination.value
    );

    return matchingEntry ? matchingEntry : 'No matching description found.'; // проверять на ошибку
});

//Variables
// export const storageVariables = 'My variable111';
export const storageVariables = computed(() => {
    const bodyminiVariables = getUserBodyminiVariables();
    const firebaseVariables = getFirebaseVariables();

    const matchingFirebaseVariable = firebaseVariables.find(variable =>
        variable.id === bodyminiVariables.variables
    );

    return matchingFirebaseVariable ? matchingFirebaseVariable : bodyminiVariables;
});
/*export const storageVariables = computed(() => {
    return getUserBodyminiVariables();
    // return getFirebaseVariables();
});*/















// Создание объекта userData
/*
export const userData = reactive({
    // firstName: initData.user.firstName, // Может быть пустым?
    // lastName: initData.user.lastName, // Может быть пустым
    name: 'Roman',
    gender: 'male', // male, female, other
    birthCity: 'Moscow',
    birthDate: '1990-01-01', // YYYY-MM-DD
    birthTime: '12:00', // 24-hour format
});

export const userFirstName = userData.name;
export const userBirthDate = userData.birthDate;
export const userBirthTime = userData.birthTime;
*/
