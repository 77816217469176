import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './style.scss';

const app = createApp(App);

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Human Design';
    next();
});

app.use(router).mount('#app');

/*
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style.scss';

createApp(App).use(router).mount('#app')*/
