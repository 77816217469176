<template>
    <div class="container mb-4">
        <Svg :src="bodygraphImage" class="w-full"/>
    </div>

    <div class="container grid grid-cols-1 gap w-full">
        <template v-for="(center, key) in bodygraph.center" :key="key">
            <div class="card card-compact" :class="storageBodyminiBodygraph.center[key] ? 'border-4 border-primary' : '' ">
                <div class="card-body">

                    <div class="flex">
                        <h4 class="title grow">{{ center.title }} Center</h4>

                        <div v-if="storageBodyminiBodygraph.center[key]">
                            <span class="bg-primary px-2 py-1 rounded text-neutral font-bold">
                                {{ storageBodyminiBodygraph.center[key] ? 'Defined' : "Undefined" }}
                            </span>
                        </div>
                    </div>

                    <code class="grid gap-0 my-2">
                        <span v-for="gate in center.gates"
                              :key="gate"
                              class="flex gap-2"
                              :class="findGateLine(storageBodyminiBodygraph, gate) ? 'font-bold' : ' text-muted' "
                        >

                            <span>{{ gate }}</span>

                            <span
                                class="flex gap-1"
                                v-if="findGateLine(storageBodyminiBodygraph, gate)"
                            >
                                <span class="font-normal">——→</span>
                                <span :class="findGateLine(storageBodyminiBodygraph, gate).lineFromDesign ? 'text-error' : 'text-primary'">
                                    line {{ findGateLine(storageBodyminiBodygraph, gate).line }}
                                </span>
                            </span>
                        </span>
                    </code>

                    <div class="flex gap text-muted hidden">
                        <code>id: {{ center.id }}</code>
                        |
                        <code>center: {{ key }}</code>
<!--                        <b v-if="storageBodyminiBodygraph.center[key]" class="text-primary">
                            {{ storageBodyminiBodygraph.center[key] }}
                        </b>-->
                    </div>

                    <div class="text-muted hidden">{{center.tags}}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { storageBodyminiBodygraph } from '@/data/storage.js'
import Svg from "@/components/Svg.vue";

const bodygraphImage = ref(require('@/assets/images/bodygraph/bodygraph.svg'));

const bodygraph = ref({
    center: {
        'head' : {
            id: 1,
            title: 'Head', //(crown)
            tags: 'inspiration, mental pressure, confusion',
            gates: ["64", "61", "63"]
        },
        'ajna' : {
            id: 2,
            title: 'Ajna', //(mind)
            tags: 'thoughts, ideas, insights',
            gates: ["47", "24", "4", "17", "11", "43"]
        },
        'throat': {
            id: 3,
            title: 'Throat',
            tags: 'communication, expression, conversations',
            gates: ["62", "23", "56", "16", "20", "31", "8", "33", "7", "45", "12", "35"]
        },
        'self': {
            id: 4,
            title: 'Self',
            tags: 'identity, direction, love',
            gates: ["10", "25", "46", "15", "7", "1", "13", "2"]
        },
        'sacral': {
            id: 5,
            title: 'Sacral',
            tags: 'life energy, sexuality, driving force',
            gates: ["34", "5", "14", "29", "59", "3", "9", "42", "27"]
        },
        'root': {
            id: 6,
            title: 'Root',
            tags: 'ambition, stress, adrenaline',
            gates: ["58", "38", "54", "53", "60", "52", "19", "39", "41"]
        },
        'splenic': {
            id: 7,
            title: 'Splenic',
            tags: 'natural fears, intuition, health',
            gates: ["48", "57", "44", "50", "32", "28", "18"]
        },
        'ego': {
            id: 8,
            title: 'Ego', //(heart)
            tags: 'self-esteem, willpower, ego',
            gates: ["51", "21", "40", "26"]
        },
        'emo': {
            id: 6,
            title: 'Emotions',
            tags: 'emotions, mood, passion',
            gates: ["36", "22", "37", "6", "55", "30", "49"]
        },
    }
});

function findGateLine(storageBodyminiBodygraph, gate) {
    const planets = Object.keys(storageBodyminiBodygraph.P.data);
    for (const planet of planets) {
        if (storageBodyminiBodygraph.P.data[planet]?.gate?.gate === gate) {
            return {
                line: storageBodyminiBodygraph.P.data[planet].gate.line,
                lineFromPersonality: true,
                lineFromDesign: false
            };
        }
        if (storageBodyminiBodygraph.D.data[planet]?.gate?.gate === gate) {
            return {
                line: storageBodyminiBodygraph.D.data[planet].gate.line,
                lineFromPersonality: false,
                lineFromDesign: true
            };
        }
    }
    return null;
}

/*function findGateLine(storageBodyminiBodygraph, gate) {
    const planets = Object.keys(storageBodyminiBodygraph.P.data);
    for (const planet of planets) {
        if (storageBodyminiBodygraph.P.data[planet]?.gate?.gate === gate) {
            return {
                line: storageBodyminiBodygraph.P.data[planet].gate.line,
                lineFromPersonality: true,
                lineFromDesign: false
            };
        }
        if (storageBodyminiBodygraph.D.data[planet]?.gate?.gate === gate) {
            return {
                line: storageBodyminiBodygraph.D.data[planet].gate.line,
                lineFromPersonality: false,
                lineFromDesign: true
            };
        }
    }
    return null;
}*/

/*function findGateLine(storageBodyminiBodygraph, gate) {
    const planets = Object.keys(storageBodyminiBodygraph.P.data);
    for (const planet of planets) {
        if (storageBodyminiBodygraph.P.data[planet]?.gate?.gate === gate) {
            return storageBodyminiBodygraph.P.data[planet].gate.line;
        }
        if (storageBodyminiBodygraph.D.data[planet]?.gate?.gate === gate) {
            return storageBodyminiBodygraph.D.data[planet].gate.line;
        }
    }
    return null;
}*/

/*const centerIsDefined = (key) => {
    return!!storageBodyminiBodygraph.center[key];
}*/

</script>