import { createRouter, createWebHistory } from 'vue-router'

import Bodygraph from '@/pages/Bodygraph.vue'
import Transits from '@/pages/Transits.vue'
import Guides from '@/pages/Guides.vue'
import Personality from '@/pages/Personality.vue'

import Onboarding from '@/pages/Onboarding.vue'
import Profile from '@/pages/Profile.vue'

const routes = [
    {
        path: '/personality',
        name: 'Personality',
        navigation: true,
        component: Personality,
        meta: { title: 'Personality', description: 'Explore your personality traits' }
    },
    {
        path: '/bodygraph',
        name: 'Bodygraph',
        navigation: true,
        component: Bodygraph,
        meta: { title: 'Bodygraph' }
    },
    {
        path: '/transits',
        name: 'Transits',
        navigation: true,
        component: Transits,
        meta: { title: 'Transits' }
    },
    {
        path: '/guides',
        name: 'Guides',
        navigation: true,
        component: Guides,
        meta: { title: 'Guides' }
    },

    {
        path: '/profile',
        name: 'Profile',
        navigation: false,
        component: Profile,
        meta: { title: 'Profile' }
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        navigation: false,
        component: Onboarding,
        meta: { title: 'Onboarding' }
    },

/*    {
        path: '/',
        redirect: '/transits',
    }*/
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next('/transits');
    } else {
        next();
    }
});

export default router