<template>
    <section class="container mb-6" :class="{[flexClasses]: flexIs}">

        <div :class="{['grow']: flexIs}">
            <!-- Title -->
            <h1 class="title">
                <slot />
            </h1>
            <!-- Description -->
            <p v-if="description" class="capitalize mt-2">
                {{description}}
            </p>
        </div>

        <!-- Settings -->
        <div v-if="settings" class="dropdown dropdown-end">
            <div tabindex="0" role="button">
                <svg :class="settingsAvatarSizeClasses" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="16" fill="white"/>
                    <g clip-path="url(#clip0_3451_1676)">
                        <path d="M14 26.8573L14.0559 26.7454C15.0723 24.7127 17.1499 23.4287 19.4225 23.4287H20.5775C22.8501 23.4287 24.9277 24.7127 25.9441 26.7454L26 26.8573" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <circle cx="20" cy="16" r="4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_3451_1676">
                            <rect width="24" height="24" fill="white" transform="translate(8 8)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <ul tabindex="0" class="dropdown-content menu bg-neutral rounded-box z-[1] w-52 p-2 mt-2 shadow">
                <li v-for="route in allRoutes" :key="route.path">
                    <router-link :to="route.path" class="hover:bg-light">{{ route.name }}</router-link>
                </li>
            </ul>
        </div>

    </section>
</template>

<script setup>
import { defineProps, computed } from 'vue'

// router
import { useRouter } from 'vue-router'
const router = useRouter();
// const allRoutes = computed(() => router.options.routes);
const allRoutes = computed(() => router.options.routes.filter(route => !route.navigation));

const props = defineProps({
    description: String,
    settings: {
        type: Boolean,
        default: false
    }
})

const flexIs = computed(() => props.settings);
const flexClasses = 'flex items-start';

const settingsAvatarSizeClasses = 'w-10 h-10';
</script>